import React from "react";
import { useMutation } from "@apollo/client";
import { mutation, queries } from "@helpers/queryStrings";

export const withMutateAddCart =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [cart, cartData] = useMutation(mutation.createCart);

    return <ComponentToWrap {...props} cartData={cartData} cart={cart} />;
  };

export const withMutateUpdateCart =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [cartUpdate, cartUpdateData] = useMutation(mutation.updateCart);

    return (
      <ComponentToWrap
        {...props}
        cartUpdateData={cartUpdateData}
        cartUpdate={cartUpdate}
      />
    );
  };

export const withMutateAddMatch =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [matchCreate, matchData] = useMutation(mutation.createMatch, {
      refetchQueries: [
        {
          query: queries.notionMKBList,
        },
      ],
    });

    return (
      <ComponentToWrap
        {...props}
        matchData={matchData}
        matchCreate={matchCreate}
      />
    );
  };
export const withMutateAddCredit =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [creditCreate, creditData] = useMutation(mutation.createCredit, {
      refetchQueries: [
        {
          query: queries.mkbCredit,
        },
        {
          query: queries.mkbPlayerCredit,
        },
      ],
    });

    return (
      <ComponentToWrap
        {...props}
        creditData={creditData}
        creditCreate={creditCreate}
      />
    );
  };
export const withMutatePatchPages =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [patchPages, patchData] = useMutation(mutation.patchPages, {
      refetchQueries: [
        {
          query: queries.mkbCredit,
        },
        {
          query: queries.mkbPlayerCredit,
        },
        {
          query: queries.notionMKBList,
        },
      ],
    });

    return (
      <ComponentToWrap
        {...props}
        patchData={patchData}
        patchPages={patchPages}
      />
    );
  };
