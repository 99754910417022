import Layout from "@components/layout";

import React, { useEffect, useState } from "react";
import { withMKBList } from "@helpers/hoc/withQueries";
import { withMutatePatchPages } from "@helpers/hoc/withMutations";

import SEO from "@components/common/seo";
import { QuestionCircleOutlined, MonitorOutlined } from "@ant-design/icons";
import "@styles/global.styles.css";
import {
  Collapse,
  Button,
  Divider,
  Tag,
  Spin,
  Skeleton,
  Popconfirm,
} from "antd";
import { sortByDate } from "@helpers/tools";
import { useFormContext } from "@helpers/hooks/formContext"; // Adjust the import path
import { navigate } from "gatsby";

type Props = {
  location?: {
    pathname: string;
  };
  mkbList: {
    notionMKBList: any;
  };
  mkbPlayers: {
    notionMKBPlayers: any;
  };
  matchData: any;
  matchCreate: Function;
  creditData: any;
  creditCreate: Function;
  refetchNotionMKBList: Function;
  refetchMkbCredit: Function;
  refetchMkbPlayerCredit: Function;
  patchPages: Function;
  mkbAction: any;
  mkbCredit: any;
  mkbPlayerCredit: any;
  loadingMkbPlayers: boolean;
  loadingMkbAction: boolean;
  loadingMkbCredit: boolean;
  loadingMKBList: boolean;
  loadingMkbPlayers: boolean;
  loadingMkbPlayerCredit: boolean;
};

const Home: React.FC<Props> = ({
  location,
  mkbList,
  refetchNotionMKBList,
  matchData,
  loadingMKBList,
  patchPages,
}) => {
  const list = sortByDate(mkbList?.notionMKBList?.data?.results);

  useEffect(() => {
    refetchNotionMKBList();
  }, [matchData]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!loadingMKBList) {
      setIsLoading(false);
    }
  }, [loadingMKBList]);
  const { setFormValues } = useFormContext();

  return (
    <Layout location={location.pathname}>
      <SEO title="Badminton" />

      <div className="mt-4">
        <Skeleton loading={isLoading} active>
          <div>
            <Spin tip="Loading" size="large" spinning={loadingMKBList}>
              <Divider orientation="left">
                Game Histories <MonitorOutlined />
              </Divider>
              <div className="m-2">
                {list?.map((res, index) => {
                  return (
                    <Collapse
                      key={index}
                      ghost
                      items={[
                        {
                          key: "1",
                          label: res.date,
                          children: (
                            <>
                              {res?.items?.map((get, num) => {
                                const loserS =
                                  get.properties["Loser Score"].number;

                                const handleMatchClick = match => {
                                  if (setFormValues) {
                                    setFormValues({
                                      wp1: match.properties.PW1.relation[0].id,
                                      wp2: match.properties.PW2.relation[0].id,
                                      lp1: match.properties.PL1.relation[0].id,
                                      lp2: match.properties.PL2.relation[0].id,
                                    });
                                    navigate("/");
                                  } else {
                                    console.error(
                                      "setFormValues is not available"
                                    );
                                  }
                                };
                                const reverseIndex = res?.items?.length - num;
                                return (
                                  <div key={num}>
                                    <div
                                      className="mb-1 cursor-pointer"
                                      onClick={() => handleMatchClick(get)}
                                    >
                                      Match {reverseIndex}:{" "}
                                      {/* Display the reverse index */}
                                    </div>
                                    <Tag color="geekblue">
                                      {loserS > 21 ? loserS + 2 : 21}-{loserS}
                                    </Tag>
                                    <Popconfirm
                                      title="Delete the record"
                                      description="Are you sure to delete this record?"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      onConfirm={() => {
                                        patchPages({
                                          variables: { input: get.matchId },
                                        });
                                      }}
                                    >
                                      <Button type="link" danger>
                                        Delete
                                      </Button>
                                    </Popconfirm>
                                    <br />
                                    {
                                      get.properties.pw1.rollup.array[0]
                                        .title[0].plain_text
                                    }
                                    /
                                    {
                                      get.properties.pw2.rollup.array[0]
                                        .title[0].plain_text
                                    }
                                    <Tag bordered={false} color="success">
                                      winners
                                    </Tag>
                                    <Tag>VS</Tag>{" "}
                                    {
                                      get.properties.pl1.rollup.array[0]
                                        .title[0].plain_text
                                    }
                                    /
                                    {
                                      get.properties.pl2.rollup.array[0]
                                        .title[0].plain_text
                                    }
                                    <Tag bordered={false} color="error">
                                      losers
                                    </Tag>
                                    <Divider />
                                  </div>
                                );
                              })}
                            </>
                          ),
                        },
                      ]}
                    />
                  );
                })}
              </div>
            </Spin>
          </div>
        </Skeleton>
      </div>
    </Layout>
  );
};

export default withMutatePatchPages(withMKBList(Home));
